/* eslint-disable camelcase */
import _ from "underscore";
import Services from "services";
import BaseDetailsView from "views/components/accountDetails/baseDetailsView";
import CashTemplate2 from "templates/accountDetails/cash2.html";
import ManageOnUsBankTemplate from "templates/accountDetails/manageOnUsBank.html";
import dollarAndCentsAmount from "templates/helpers/dollarAndCentsAmount";
import BackboneReactView from "common/BackboneReactView";
import SweepProgramDetailsContainer from "components/hysa/SweepProgramDetails/Container/SweepProgramDetailsContainer";
import InviteSecondaryAccountOwnerModal from "components/hysa/InviteSecondaryAccountOwner/Modal/InviteSecondaryAccountOwnerModal";
import * as ServiceUtils from "utils/service";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import * as EnrollmentUtils from "components/hysa/utils/enrollment";
import AccountInformationModal from "components/hysa/AccountInformation/Modal/AccountInformationModal";
import "react";
import "tooltip";
import mixpanel from "../../../libs/pcap/utils/mixpanel";

var MAX_LENGTH_FIRM_NAME = 45;
var MAX_LENGTH_NAME = 40;
const MARGIN_RIGHT_LABELS_X = 34;
const WIDTH_EXTRA_SPACE = 40;

function isLoggedInUserAccountOwner(owners) {
  const loggedInPerson = window.personId;
  return owners.some(
    (o) => o.personId === loggedInPerson && o.enrollmentStatus === "OPENED"
  );
}

var CashView = BaseDetailsView.extend(
  {
    events: function () {
      return Object.assign({}, BaseDetailsView.prototype.events, {
        "click .js-action-add-account-owner": "handleAddAccountOwnerClick",
        "click .js-action-account-information": "handleAccountInformationClick",
        "click [data-analytic-event]": "handleAnalyticsButtonClick",
        "click [data-amplitude-event]": "handleAmplitudeButtonClick",
      });
    },
    initialize: function () {
      BaseDetailsView.prototype.initialize.apply(this, arguments);
      this.getBalanceHistory(true);
      mixpanel.trackEvent("View Account Details Cash", {
        component: "cashView",
      });
    },
    render: function () {
      var data = this.model.toJSON();

      data.cappedFirmName = this.capStringLength(
        data.firmName,
        MAX_LENGTH_FIRM_NAME
      );
      data.cappedName = this.capStringLength(data.name, MAX_LENGTH_NAME);

      data.showRefetchTransaction = this.options.showRefetchTransaction;
      data.isRefetchTransactionEligible =
        this.options.isRefetchTransactionEligible;
      this.$el.html(CashTemplate2(data));
      BaseDetailsView.prototype.render.call(this);

      if (this.addAccountOwnerView) {
        this.addAccountOwnerView.remove();
        delete this.addAccountOwnerView;
      }

      if (this.accountInformationView) {
        this.accountInformationView.remove();
        delete this.accountInformationView;
      }

      if (data.isOnUsBank) {
        // Intentionally skips error processing because the API call
        // updates a small section of the page that is hidden behind a dropdown.
        this.fetchPcbAccountInfoRequest = makeCancelablePromise(
          CashView.fetchPcbAccountInfo({ userAccountId: data.userAccountId })
        );
        this.fetchPcbAccountInfoRequest.promise.then(({ owners }) => {
          const isOwner = isLoggedInUserAccountOwner(owners);
          this.renderSweepProgramDetailsContainer(isOwner);
          if (isOwner) {
            // `owners` from `accountInfo` API reflect the relationship more accurately then `account.ownershipType`.
            // The ownership type remains `INDIVIDUAL` while the secondary owner completes their portion of the enrollment.
            const isIndividualOnUsBankAccount =
              owners.length === 1 ||
              owners.some(EnrollmentUtils.unfinishedEnrollment);
            const isAccountClosed = Boolean(data.closedDate);
            this.el.querySelector(
              ".js-account-details-action-container"
            ).innerHTML = ManageOnUsBankTemplate(
              Object.assign(data, {
                isIndividualOnUsBankAccount,
                showCloseCashAccount:
                  this.options.showCloseCashAccount && !isAccountClosed,
                isAccountClosed,
              })
            );
          }
        }, _.noop);
      }
      this.$('[data-toggle="tooltip"]').tooltip();
      return this;
    },
    changeAccount: function (account) {
      BaseDetailsView.prototype.changeAccount.call(this, account);

      this.getBalanceHistory();
    },
    /*
     * If only account info has been updated, then only update specific DOM elements
     */
    updateContent: function () {
      BaseDetailsView.prototype.updateContent.call(this);
      var htmlText;

      // update balance
      htmlText = dollarAndCentsAmount(this.model.get("balance"), true);
      this.$el.find(".balance.dataValue").text(htmlText); // check if this has ever worked

      // update availableBalance
      htmlText = dollarAndCentsAmount(this.model.get("availableBalance"), true);
      this.$el.find(".availableBalance.dataValue").text(htmlText); // check if this has ever worked
      this.$el.find(".js-available-balance-value").text(htmlText);
    },
    getHistory: function () {
      this.getBalanceHistory();
    },
    onBalanceFetched: function (err, response) {
      if (err === null) {
        BaseDetailsView.prototype.onBalanceFetched.call(this, err, response);

        const balanceChartEl = this.$el.find("#balanceSVG");
        const originalWidth = balanceChartEl.width();
        this.renderBalanceChart();
        if (balanceChartEl && originalWidth) {
          balanceChartEl
            .find("svg")
            .css({ width: originalWidth + WIDTH_EXTRA_SPACE });
          balanceChartEl
            .find(".endDate")
            ?.css({ "margin-right": MARGIN_RIGHT_LABELS_X });
        }
      }
    },
    updateReopenedAccountDetails: function () {
      BaseDetailsView.prototype.updateReopenedAccountDetails.call(this);

      var htmlText;

      // update balance
      htmlText = dollarAndCentsAmount(this.model.get("balance"), true);
      this.$el.find(".balance.dataValue").text(htmlText);

      // update availableBalance
      htmlText = dollarAndCentsAmount(this.model.get("availableBalance"), true);
      this.$el.find(".availableBalance.dataValue").text(htmlText);
    },
    handleAddAccountOwnerClick: function (ev) {
      ev.preventDefault();
      if (window.hj) {
        window.hj("trigger", "hj-joint-pcc-enrollment");
      }
      this.renderAddAccountOwnerView({ isOpen: true });
    },
    handleAccountInformationClick: function (ev) {
      ev.preventDefault();
      this.renderAccountInformationView({ isOpen: true });
    },
    renderAddAccountOwnerView: function (props) {
      if (this.addAccountOwnerView) {
        this.addAccountOwnerView.update(props);
      } else {
        const closeModal = () => {
          this.renderAddAccountOwnerView({ isOpen: false });
        };
        this.addAccountOwnerView = new BackboneReactView({
          component: InviteSecondaryAccountOwnerModal,
          className: "js-add-account-owner",
          componentProps: Object.assign(
            {
              userAccountId: this.model.get("userAccountId"),
              onCancel: closeModal,
              onWithdrawnInvitation: closeModal,
              onDone: closeModal,
            },
            props
          ),
        });
        document.body.appendChild(this.addAccountOwnerView.el);
      }
    },
    renderAccountInformationView: function (props) {
      if (this.accountInformationView) {
        this.accountInformationView.update(props);
      } else {
        this.accountInformationView = new BackboneReactView({
          component: AccountInformationModal,
          className: "js-account-info-modal",
          componentProps: Object.assign(
            {
              userAccountId: this.model.get("userAccountId"),
              onDone: () => {
                this.renderAccountInformationView({ isOpen: false });
              },
            },
            props
          ),
        });
        document.body.appendChild(this.accountInformationView.el);
      }
    },
    renderSweepProgramDetailsContainer: function (isOwner) {
      // remove as the user is switching between two cash account views
      if (this.sweepProgramDetailsContainer) {
        this.sweepProgramDetailsContainer.remove();
      }

      this.sweepProgramDetailsContainer = new BackboneReactView({
        component: SweepProgramDetailsContainer,
        className: "js-sweep-program-details pc-u-mr--",
        componentProps: Object.assign({
          userAccountId: this.model.get("userAccountId"),
          cashAccountData: {
            formattedBalance: dollarAndCentsAmount(
              this.model.get("balance"),
              true,
              true
            ),
            isClosed: this.model.get("closedDate"),
          },
          isOwner,
        }),
      });
      const containerEl = this.$el.find(".js-sweep-program-container");
      if (containerEl.length) {
        containerEl.append(this.sweepProgramDetailsContainer.el);
      }
    },
    remove() {
      if (this.sweepProgramDetailsContainer) {
        this.sweepProgramDetailsContainer.remove();
      }
      if (this.addAccountOwnerView) {
        this.addAccountOwnerView.remove();
      }
      if (this.accountInformationView) {
        this.accountInformationView.remove();
      }
      if (this.fetchPcbAccountInfoRequest) {
        this.fetchPcbAccountInfoRequest.cancel();
      }
      BaseDetailsView.prototype.remove.apply(this, arguments);
    },
    handleAmplitudeButtonClick(e) {
      const amplitudeEvent = e.target.dataset.amplitudeEvent;
      if (amplitudeEvent) {
        window.dashboardUtils?.eventBus?.dispatchAmplitude({
          event_type:
            window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON ??
            "select_button",
          event_properties: {
            selection: amplitudeEvent,
          },
        });
      }
    },
    handleAnalyticsButtonClick(e) {
      window.dashboardUtils?.eventBus.dispatch(e.target.dataset.analyticEvent);
    },
  },
  {
    fetchPcbAccountInfo: ServiceUtils.promisify(
      Services.PCBAccount.accountInfo
    ),
  }
);

export default CashView;
