var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <a\n    href=\"#/transfer-funds?transferType=1&to="
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"userAccountId") : depth0), depth0))
    + "\"\n    data-attribution-source=\"fund-now-account-details-page\"\n    class=\"pc-btn pc-u-mr-- js-action-fund-now\"\n    data-analytic-event=\"cash.account_details_page_fund_now.click\"\n  >\n    Fund Now\n  </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <li class=\"menu__item\">\n        <a\n          href=\"#/transfer-funds?origin="
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"userAccountId") : depth0), depth0))
    + "\"\n          data-attribution-source=\"transfer-funds-account-details-page\"\n          class=\"menu__action\"\n          data-analytic-event=\"cash.transfer_funds_menu.click\"\n          >Transfer Funds</a\n        >\n      </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isAccountClosed") : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":8},"end":{"line":68,"column":19}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isIndividualOnUsBankAccount") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":10},"end":{"line":67,"column":17}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "          <li class=\"menu__item\">\n            <a href=\"#\" class=\"menu__action js-action-add-account-owner\"\n              >Add Account Owner</a\n            >\n          </li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <li class=\"menu__item\">\n        <a\n          href=\"#/close-bank-account?ua="
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"userAccountId") : depth0), depth0))
    + "\"\n          data-testid=\"hysa-close-account-menu-link\"\n          class=\"menu__action\"\n          data-analytic-event=\"cash.close_account_menu.click\"\n          data-amplitude-event=\"close_account\"\n        >\n          Close Account\n        </a>\n      </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"js-manage-pcc-account\">\n"
    + ((stack1 = __default(require("../helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nextAction") : depth0)) != null ? lookupProperty(stack1,"action") : stack1),"===","PCB_FUND_NOW",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":11,"column":14}}})) != null ? stack1 : "")
    + "  <div class=\"dropdown\">\n    <button\n      class=\"pc-btn pc-btn--toggle\"\n      type=\"button\"\n      data-toggle=\"pc-dropdown\"\n      data-analytic-event=\"cash.manage_account_menu_open.click\"\n      data-amplitude-event=\"cash_account_info\"\n    >\n      Manage Account\n      <div\n        class=\"pc-toggle pc-triangle-down pc-triangle-down--small pc-toggle--tiny\"\n      ></div>\n    </button>\n    <ul class=\"menu menu--vertical menu--bordered u-text-left\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAccountClosed") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":6},"end":{"line":36,"column":17}}})) != null ? stack1 : "")
    + "      <li class=\"menu__item\">\n        <a\n          href=\"#\"\n          class=\"menu__action js-action-account-information\"\n          data-analytic-event=\"cash.account_information_menu.click\"\n          >Account Information</a\n        >\n      </li>\n      <li class=\"menu__item\">\n        <a\n          href=\"#/settings/profile\"\n          class=\"menu__action\"\n          data-analytic-event=\"cash.account_settings_menu.click\"\n        >Account Settings</a>\n      </li>\n      <li class=\"menu__item\">\n        <a\n          href=\"#/documents\"\n          class=\"menu__action\"\n          data-analytic-event=\"cash.view_statements_menu.click\"\n        >View Statements</a>\n      </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEmpower") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":6},"end":{"line":69,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showCloseCashAccount") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":6},"end":{"line":82,"column":13}}})) != null ? stack1 : "")
    + "    </ul>\n  </div>\n</div>\n";
},"useData":true});