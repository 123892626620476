/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import TransferDetailsBox from "../TransferDetailsBox";
import WarningMessage from "../../WarningMessage/WarningMessage";
import Confirm from "svg-icons/Confirm.svg";
import PershingMessages from "../../PershingMessages/PershingMessages";
import {
  TRANSFER_TYPE_CONTRIBUTE,
  TRANSFER_TYPE_WITHDRAW,
} from "../../../../components/transferFunds/utils/constants";

class TransferConfirmation extends React.Component {
  componentDidMount() {
    if (IS_EMPOWER) {
      const { transferType } = this.props;
      window.dashboardUtils?.eventBus.dispatch("transfer_submitted.page.load");
      if (
        [TRANSFER_TYPE_CONTRIBUTE, TRANSFER_TYPE_WITHDRAW].includes(
          transferType
        )
      ) {
        const eventType =
          transferType === TRANSFER_TYPE_WITHDRAW
            ? "endflow_account_withdrawal_cash"
            : "endflow_account_funding_cash";
        window.dashboardUtils?.eventBus.dispatchAmplitude({
          event_type: eventType,
          event_properties: {
            selection: `${eventType}.view-page`,
          },
        });
      }
    }
  }

  render() {
    const {
      sourceAccount,
      targetAccount,
      model,
      onMakeAnotherTransfer,
      onBackToHome,
      onClose,
      messageSuccess,
      messageWarning,
      pershingMessages,
      showTitle,
      displayAdvisorElements,
    } = this.props;
    const isEmpowerPersonalCashTransfer =
      sourceAccount.isOnUsBank || targetAccount.isOnUsBank;
    return (
      <div>
        {!messageWarning && (
          <div>
            {showTitle && (
              <h1 className="nav-secondary__title js-transfer-confirmation-title">
                {isEmpowerPersonalCashTransfer
                  ? "Transfer request submitted"
                  : "Confirmed!"}
              </h1>
            )}
            <div className="pc-flag--top pc-flag--small pc-u-mt">
              <div className="pc-flag__img">
                <Confirm className="transfer-confirmation__icon" />
              </div>
              <div className="pc-flag__body">
                <p className="transfer-confirmation__text">
                  {isEmpowerPersonalCashTransfer
                    ? "Your request has been received and will be processed shortly."
                    : "Your request is complete."}
                </p>
                <div className="transfer-confirmation__text-sub centi">
                  {messageSuccess}
                  {!messageSuccess && isEmpowerPersonalCashTransfer && (
                    <>
                      <span className="pc-label">
                        The estimated completion timeframe is 2-5 business days.
                      </span>
                      <span className="pc-label">
                        If you have any questions regarding this transfer please
                        contact your advisor or our support team.
                      </span>
                    </>
                  )}
                  {model.transactionReferenceId && displayAdvisorElements && (
                    <p>
                      Your confirmation number: {model.transactionReferenceId}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {messageWarning && (
          <div>
            {showTitle && (
              <h1 className="nav-secondary__title js-transfer-confirmation-title">
                Just one more step
              </h1>
            )}
            <div className="pc-layout--auto pc-u-mt">
              <WarningMessage
                message={messageWarning}
                containerClassName="transfer-confirmation__warning-notification-message__container"
                iconClassName="transfer-confirmation__warning-notification-message__icon"
              />
            </div>
          </div>
        )}
        {pershingMessages && displayAdvisorElements && (
          <div className="pc-layout--auto pc-u-mt">
            <PershingMessages messageArray={pershingMessages} />
          </div>
        )}
        <h2 className="transfer-confirmation__body-title">Request details</h2>
        <TransferDetailsBox
          sourceAccount={sourceAccount}
          targetAccount={targetAccount}
          model={model}
        />
        <div className="pc-layout pc-layout--right pc-u-pt+">
          {onMakeAnotherTransfer && (
            <button
              type="button"
              className="pc-btn back-btn js-transfer-confirmation-make-another-btn"
              onClick={onMakeAnotherTransfer}
            >
              Make another request
            </button>
          )}
          {onBackToHome && (
            <button
              type="button"
              className="pc-btn pc-btn--primary js-transfer-confirmation-back-to-home-btn"
              onClick={onBackToHome}
            >
              Back to home
            </button>
          )}
          {onClose && (
            <button
              type="button"
              className="pc-btn pc-btn--primary js-transfer-confirmation-close-btn"
              onClick={onClose}
            >
              Close
            </button>
          )}
        </div>
      </div>
    );
  }
}

TransferConfirmation.propTypes = {
  sourceAccount: PropTypes.object.isRequired,
  targetAccount: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  onMakeAnotherTransfer: PropTypes.func,
  onBackToHome: PropTypes.func,
  onClose: PropTypes.func,
  messageWarning: PropTypes.string,
  pershingMessages: PropTypes.array,
  messageSuccess: PropTypes.string,
  showTitle: PropTypes.bool,
  displayAdvisorElements: PropTypes.bool,
  transferType: PropTypes.number.isRequired,
};

TransferConfirmation.defaultProps = {
  messageWarning: undefined,
  pershingMessages: undefined,
  messageSuccess: undefined,
  showTitle: true,
  onMakeAnotherTransfer: undefined,
  onBackToHome: undefined,
  onClose: undefined,
  displayAdvisorElements: false,
};

export default TransferConfirmation;
